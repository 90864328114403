html,
body {
  height: 100%;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-wrapper {
  flex-grow: 1;
}

.footer {
  flex-shrink: 0;
}

body {
  -webkit-font-smoothing: antialiased;
}

img {
  max-width: 100%;
  height: auto;
}

@include media-breakpoint-down(xs) {
  .hide-for-mobile {
    display: none !important;
  }
}

a {
  transition: 0.2s;
}

a:hover {
  text-decoration: none;
  transition: 0.2s;
}

a:focus {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

h3 {
  margin-top: 0;
}

.error-message-color {
  color: #f00;
}

#privacy_policy h5 {
  font-size: 1.2rem;
  font-weight: 600;
}

#privacy_policy h6 {
  font-size: 1.05rem;
  font-weight: 700;
}

label {
  margin-bottom: 0px;
  font-weight: normal;
  display: block;
}

.button-default {
  text-decoration: none !important;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214 */
  white-space: nowrap;
  padding: 11px 25px;
  height: auto;
  transition: all 0.5s ease-out;
  letter-spacing: 1px;
  text-transform: uppercase;
  outline: 0;
}

.button-fullwidth {
  width: 100%;
}

.button-primary[disabled] {
  background-color: #f6f6f6 !important;
  color: #b6b6b6 !important;
  cursor: default;
  border: none !important;
}

.button-primary[disabled]:hover,
.button-primary[disabled]:active,
.button-primary[disabled]:focus {
  background-color: #f6f6f6 !important;
  color: #b6b6b6 !important;
  cursor: default;
  border: none !important;
}

.page-title {
  margin-bottom: 40px;
  text-align: left;
  margin-top: 40px;
}

h1 {
  margin-bottom: 1.7rem;
  font-size: 1.6rem;
}

.right {
  text-align: right;
}

.table th {
  border-top: none !important;
}

.page-container ul {
  padding-left: 1.3rem;
  top: 0.25rem;
}

.page-container {
  margin-top: 3rem;
  margin-bottom: 6rem;
  font-size: 1em;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
textarea {
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  padding-bottom: 5px;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-top-width: 0 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  outline: 0;
  transition: 0.6s;
  height: 41px;
  width: 100%;
  border-width: 1px;
  border-style: solid;
}

select {
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  padding: 5px;

  border: 1px solid;
  border-radius: 0 !important;
  outline: 0;
  transition: 0.6s;
  height: 38px;
  width: 100%;
  cursor: pointer;
  background-position: 100%;
}

input[type="text"]:disabled,
input[type="text"][readonly],
input[type="password"]:disabled,
input[type="password"][readonly],
input[type="email"]:disabled,
input[type="email"][readonly],
input[type="number"]:disabled,
input[type="number"][readonly],
textarea:disabled,
textarea[readonly],
select:disabled,
select[readonly] {
  opacity: 0.6;
}

.table thead th {
  border-bottom-width: 1px;
}

.table tr td:first-child,
.table tr th:first-child {
  padding-left: 3px;
}

.table tr td:last-child,
.table tr th:last-child {
  padding-right: 3px;
}

.borderless-row td {
  border-width: 0;
}

.common_error-text {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
  padding: 0.5rem;
  background-color: #d9534f;
  border-radius: 2px;
  color: #f7dbdb;
  max-width: 400px;
}

.no-wrap {
  white-space: nowrap;
}

.common-box-title {
  font-weight: bold;
}

/* Nav & Heading */
/* ---------------------------------*/

.nav-container {
  position: relative;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.navbar {
  padding: 0;
  min-height: 70px;
  gap: 1rem;
}

.navbar-logo {
  max-width: calc(100% - 124px);
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
  @include media-breakpoint-up(md) {
    margin-right: 25px;
  }
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.nav-link {
  font-size: 120%;
  display: flex;
  align-items: center;
}

.nav-link--cart-item {
  min-width: 44px;
  height: 100%;
  text-align: center;
}

.nav-item.dropdown {
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.nav-link--lang {
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.meta-nav {
  margin-left: auto;
}

.navbar-toggler {
  width: 42px;
  height: 42px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-toggler-icon {
  width: 24px;
  height: 16px;
  position: relative;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    background-color: currentColor;
    transition: 150ms ease-in-out;

    &:nth-child(1) {
      top: 0;

      .navbar-toggler--close & {
        width: 0;
        transform: translate(12px, 9px);
      }
    }

    &:nth-child(2) {
      top: 8px;

      .navbar-toggler--close & {
        transform: rotate(45deg);
      }
    }

    &:nth-child(3) {
      top: 8px;

      .navbar-toggler--close & {
        transform: rotate(-45deg);
      }
    }

    &:nth-child(4) {
      top: 16px;

      .navbar-toggler--close & {
        width: 0;
        transform: translate(12px, -7px);
      }
    }
  }
}

.navbar-collapse {
  @include media-breakpoint-down(md) {
    background-color: var(--nav-bg-color);
    width: 320px;
    max-width: 100%;
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 110;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(.show) {
      display: block;
      opacity: 0;
      visibility: hidden;
    }

    .navbar-nav {
      border-top: 1px solid var(--mobile-nav-border-color);
      padding-top: 15px;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      /* Margin Resets for Custom CSS */
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;

      .nav-link {
        padding-top: 7px;
        padding-bottom: 7px;
        text-align: center;
        justify-content: center;
      }
    }

    .language-switcher {
      text-align: center;
    }
  }

  .language-switcher {
    max-width: 140px;
  }
}

.screen-darken {
  height: 100%;
  width: 100%;
  z-index: 102;
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity 0.2s linear, visibility 0.2s, width 2s ease-in;
}

.dropdown-toggle::after {
  content: "\f078";
  display: inline-block;
  font: normal normal normal 12px/1 FontAwesome;
  font-size: 12px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: none;
  margin-left: 4px;
}

.dropdown-menu {
  margin-top: 0;
  border-radius: 0;
  border: none;
  padding: 0;
  background: transparent;

  @include media-breakpoint-up(lg) {
    background: #fff;
    box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
    top: calc(100% - 7px);
  }
}

.navbar-expand-lg .nav-item.dropdown:hover .dropdown-menu {
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.dropdown-menu > li a {
  text-align: center;
  color: var(--nav-link-color);
  font-weight: 400;
  padding: 0.325rem;
  @include media-breakpoint-up(lg) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 0;
    font-weight: 600;
    text-align: left;
    color: #59646f;
  }
}

.dropdown-menu > li:last-of-type {
  @include media-breakpoint-up(lg) {
    padding-bottom: 1rem;
  }
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  color: var(--nav-link-color);
  background: none;
  @include media-breakpoint-up(lg) {
    color: #212529;
    text-decoration: none;
  }
}

@include media-breakpoint-up(xl) {
  .navbar-expand-lg .nav .nav-item.dropdown:hover .dropdown-menu {
    display: block;
  }
}

/* http://nicolasgallagher.com/flexible-css-cover-images/ */
.cover:before {
  padding-bottom: 50%; /* 2:1 ratio */
  content: "";
  display: block;
  width: 100%;
}

.cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: block;
  overflow: hidden;
  position: relative;

  margin: 0 auto;
  max-height: 31rem;
}

/* Products */
/* ---------------------------------*/

.lead-title {
  margin-top: 0;
  text-align: left;
  margin-bottom: 10px;
  line-height: 1.05; /* Unitless: use this number multiplied by the element's font size */
}

.lead-text {
  text-align: left;
  font-size: 1.1rem;
  margin-bottom: 50px;
}

.products-promocode-by-url-applied {
  margin-top: 0;
  margin-bottom: 35px;
  padding: 8px;
  color: #ffffff;
  background-color: green;
  border-radius: 2px;
}

.products {
  margin-bottom: 50px;
}

.product-card {
  position: relative;
  margin-bottom: 1.3rem;
}

.product-card-thumb {
  position: relative;
  width: 100%;
  height: auto;
}

.product-card-caption {
  position: relative;
  padding: 5px 8px;
  padding-left: 0;
  font-size: 1.1rem;
  line-height: 1.2;
}

.product-card-title {
  font-size: inherit;
  line-height: inherit;
}

.product-card-display-price {
  font-size: 0.95rem;
  margin-top: 8px;
  position: absolute;
  bottom: 25px;
  left: 0;
  z-index: 20;
  background-color: var(--global-background-color);
  padding: 5px 14px;
  font-weight: 700;
}

.product-card-link {
  display: block;
}

.product-card-img-section {
  position: relative;
  aspect-ratio: 513/428;
}

.products-deal-discount-teaser {
  z-index: 90;
  opacity: 0.7;
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 51px;
}

.products-deal-discount-text {
  z-index: 100;
  font-family: Arial;
  color: white;
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 51px;
  padding: 4px;
  font-size: 13px;
  text-align: center;
}

.products-deal-contingent-teaser {
  z-index: 90;
  opacity: 0.7;
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 51px;
  background-color: #000;
  color: white;
}

.products-deal-discount-teaser ~ .products-deal-contingent-teaser,
.products-deal-discount-teaser ~ .products-deal-contingent-text {
  bottom: 19px;
}

.products-deal-contingent-text {
  z-index: 100;
  font-family: Arial;
  color: white;
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 51px;
  padding: 4px;
  font-size: 13px;
  text-align: center;
}

.products-deal-contingent-soldout-teaser {
  z-index: 90;
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 51px;
  background-color: #ca5c54;
}

.products-deal-contingent-soldout-text {
  z-index: 100;
  font-family: Arial;
  color: white;
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 51px;
  padding: 4px;
  font-size: 13px;
  text-align: center;
}

.products-availability {
  font-family: Arial;
  color: white;
  padding: 4px;
  font-size: 13px;
  text-align: center;
}

.product-gallery-main {
  * {
    user-select: none;
  }

  .swiper-slide {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    display: block;
    width: 100%;
  }
}

.product-gallery-thumbs {
  margin-top: 14px;

  .swiper-slide {
    cursor: pointer;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* Events */
/* ---------------------------------*/

.products-card-event-date-box {
  position: absolute;
  top: 0;
  left: 10px;
  width: 40px;
  background-color: rgba(255, 255, 255, 1);
  color: #624a4a;
  padding: 10px;
  z-index: 60;
  font-family: Verdana, serif;
  text-align: center;
  line-height: 1;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.products-card-event-date-box-day {
  font-size: 17px;
  line-height: 0.8;
}

.products-card-event-date-box-month {
  font-size: 9px;
  text-transform: uppercase;
}

.products-card-event-soldout {
  position: absolute;
  top: 80px;
  left: 0;
  background-color: rgba(226, 42, 42, 0.8);
  color: #fff;
  padding: 10px;
  text-align: center;
  width: 100%;
  z-index: 60;
  text-transform: uppercase;
}

.products-card-event-cancelled {
  position: absolute;
  top: 80px;
  left: 0;
  background-color: rgba(226, 42, 42, 0.85);
  color: #fff;
  padding: 10px;
  text-align: center;
  width: 100%;
  z-index: 60;
  text-transform: uppercase;
}

.products-card-event-postponed {
  position: absolute;
  top: 80px;
  left: 0;
  background-color: rgba(250, 187, 0, 0.85);
  color: #fff;
  padding: 10px;
  text-align: center;
  width: 100%;
  z-index: 999;
  text-transform: uppercase;
}

/* Product */
/* ---------------------------------*/

.product-container h1 {
  margin-bottom: 1rem;
}

.product-promocode-by-url-applied {
  margin-top: 0px;
  margin-bottom: 30px;
  padding: 8px;
  color: #ffffff;
  background-color: green;
  border-radius: 2px;
}

.product-image {
  width: 100%;
  height: auto;
}

.product-banners {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  width: 100%;
  bottom: 19px;
}

.product-deal-discount-teaser,
.product-deal-contingent-teaser,
.product-availability,
.product-discount-teaser {
  z-index: 90;
  height: 30px;
  font-family: Arial;
  color: white;
  padding: 4px;
  font-size: 16px;
  text-align: center;
  position: relative;

  &--small {
    height: 25px;
    font-size: 13px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    opacity: 0.7;
    background-color: var(--background, #000);
    z-index: -1;
  }
}

@media (min-width: 48em) {
  .product-left-box {
    width: 94%;
  }
}

.product-personalize {
  border: 1px solid;
  border-top: none;
  margin-top: 0rem;
  border-radius: 0px;
  padding: 1rem;
}

@media (max-width: 48em) {
  .product-personalize {
    border: none;
    margin-top: 30px;
    padding: 0;
  }
}

.product-personalize-title {
  margin-bottom: 1.5rem;
}

.product-personalize-element {
  margin-bottom: 20px;
}

.product-personalize-element-label {
  margin-bottom: 3px;
  font-weight: normal;
}

.product-personalize input {
  border-radius: 3px;
}

.product-personalize-label {
  font-weight: bold;
  margin-bottom: 3px;
}

.product-devotement {
  height: 5rem;
}

.product-preview {
  margin-top: 0.8rem;
}

.product-description {
  margin-bottom: 2rem;
}

.product-sold-out-alert {
  border: 2px solid #ca5c54;
  padding: 10px;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 1.4em;
  color: #ca5c54;
}

.product-options {
  margin-bottom: 2.5rem;
}

@media (min-width: 62em) {
  .product-options select,
  .product-options input[type="text"] {
    width: 27rem;
  }
}

.product_selection-row {
  margin-bottom: 30px;
}

.product_selection-name {
  margin-bottom: 6px;
  font-weight: bold;
}

.product_selection-description {
  margin-bottom: 0.3rem;
  font-size: 0.85em;
}

.product_selection-choice {
}

.product_selection-choice-article-count {
}

.product_selection-item-description {
  margin: 0.6rem 0;
  font-size: 0.85em;
}

#selection-value {
  margin: 0.3rem 0;
}

#product_selection-value-comment {
  font-size: 0.85em;
}

.product_selection-item-checkbox-label {
  display: inline;
  padding-left: 4px;
}

.product_selection-image > label {
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
}

.product_selection-image > label > input {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
}

.product_selection-image > label > input + div > img {
  border: 3px solid transparent;
  transition: 0.5s;
  pointer-events: none; /* IE 11 fix */
}

.product_selection-image > label > input:checked + div > img {
  border: 3px solid #555;
}

.img-radio {
  position: relative;
}

.product_selection-image > label > input:checked + .img-radio:before {
  content: "\f00c";
  display: block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  height: 30px;
  width: 30px;
  padding: 6px;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #555;
}

.product-price {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
}

.product-price-original-value {
  font-weight: normal;
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
}

.product-ticket-group {
  margin-bottom: 0.75rem;
  border: 1px solid #eceeef;
  position: relative;
}

.product-ticket-group-header {
  padding: 0.75rem 1.25rem;
}

.product-ticket-group-header a {
  display: block;
  margin: -0.75rem -1.25rem;
  padding: 0.75rem 1.25rem;
}

.product-ticket-group-content {
  padding: 1.25rem;
  padding-bottom: 0.75rem;
}

/* Ticket Checkout Questions */
/* ---------------------------------*/

.filepond--action-edit-item {
  opacity: 0.75 !important;
  width: 2.75em !important;
  height: 2.75em !important;
  background-color: var(--primary-button-background-color) !important;
  color: var(--primary-button-color) !important;
  left: calc(50% - 1.125em) !important;
}

.checkbox-wrapper {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

/* Cart */
/* ---------------------------------*/

.cart-total-table {
  overflow-wrap: break-word;
  width: 100%;
  table-layout: fixed;
}

.cart_cart-table-description-column-product-name {
  font-weight: bold;
}

.cart_cart-table-description-column-items {
  font-size: 0.9em;
}

.cart_cart-table-img-column,
.cart_cart-table-img-column-header {
  width: 7.5rem;
}

.cart_cart-table-qty-column-remove-item {
  font-size: 1.2em;
}

#cart_promocode-button {
  width: auto;
}

#cart_promocode-msg {
  margin-top: 0.7rem;

  color: #ff0000;
}

#cart_total-row-subtotal {
  font-weight: bold;
}

.cart-total-table td {
  padding-left: 2px;
  padding-right: 2px;
}

.cart-total-table tbody td {
  border-top: none;
  border-bottom: 1px solid;
}

.cart-total-table tfoot td {
  border-top: none;
}

.cart-total-table tfoot {
  font-weight: bold;
  font-size: 1.4em;
}

.cart-total-box {
  border: 1px solid;
  margin-left: 10px;
  padding: 18px;
}

@media (max-width: 62em) {
  .cart-total-box {
    margin-left: 0;
    margin-top: 50px;
  }
}

/* only for xs devices*/
@include media-breakpoint-down(xs) {
  .table-items-resp thead {
    display: none;
  }

  .table-items-resp tr {
    margin-bottom: 20px;
    display: block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .table-items-resp tr:last-child {
    border-bottom: none;
  }

  .table-items-resp td {
    display: block;
    padding-left: 3px !important;
    padding-right: 0;
    border: none !important;
  }
}

.cross-selling-products {
  margin-top: 70px;

  &__title {
    font-size: 1.3rem;
    font-weight: 800;
  }

  .cross-selling-slider {
    position: relative;
  }

  .swiper-navigation {
    top: 50%;
    margin-top: -51px;
    left: 0;
    right: 0;
    position: absolute;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    .swiper-button-prev:after,
    .swiper-rtl .swiper-button-next:after {
      content: "\f053";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }

    .swiper-button-next:after,
    .swiper-rtl .swiper-button-prev:after {
      content: "\f054";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }

    .swiper-button-prev {
      color: inherit;
      left: -40px;
      top: auto;
      margin-top: 0;

      @media all and (min-width: 1300px) {
        left: -60px;
      }
    }

    .swiper-button-next {
      color: inherit;
      right: -40px;
      top: auto;
      margin-top: 0;

      @media all and (min-width: 1300px) {
        right: -60px;
      }
    }
  }

  .product-card {
    margin-bottom: 0;

    .product-card-title:only-child {
      margin-bottom: 0;
    }
  }

  .product-card-caption {
    font-size: 1rem;
  }
}

/* Address */
/* ---------------------------------*/

.address-row {
  margin-bottom: 10px;
}

.address_row-label {
  padding-top: 10px;
}

.address-row-payment {
  margin-top: 50px;
}

#address_terms {
  margin-top: 25px;
  margin-bottom: 10px;
}

.address-row label.error {
  margin-top: 4px;
  color: #f00;
}

.shipping-choice-item {
  label:not(.error) {
    display: flex;
    align-items: baseline;
    gap: 10px;
    padding: 1rem;
    border: 1px solid var(--border-color);
    cursor: pointer;
  }

  &--disabled label:not(.error) {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.processing-overlay {
  background: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner_V8m1 {
  transform-origin: center;
  animation: spinner_zKoa 2s linear infinite;
}
.spinner_V8m1 circle {
  stroke-linecap: round;
  animation: spinner_YpZS 1.5s ease-in-out infinite;
}

@keyframes spinner_zKoa {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner_YpZS {
  0% {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }
  47.5% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -16;
  }
  95%,
  100% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -59;
  }
}

/* Overview */
/* ---------------------------------*/

.overview-processing-payment {
  background-color: black;
  opacity: 0.5;
  z-index: 9000;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  overflow: hidden;
}

.overview-test-credit-card {
  z-index: 10000;
  position: fixed;
  left: 0;
  bottom: 14px;
  right: 0;
  text-align: center;
}

.button-test-payment {
  background-color: green;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  text-transform: none;
  border: 3px solid white;
}

.button-test-payment:hover {
  color: rgb(240, 240, 240);
}

.overview-right-box {
  border: 1px solid #eceeef;
  margin-left: 10px;
  padding: 18px;
}

@media (max-width: 62em) {
  .overview-right-box {
    margin-left: 0;
    margin-top: 50px;
  }
}

.overview_info-box-row {
  margin-bottom: 4px;
}

.overview_info-box-title {
  font-weight: bold;
}

/* Checkout */
/* ---------------------------------*/

.checkout-right-box {
  padding: 20px;
  border: 1px solid #eceeef;
}

/* Completion */
/* ---------------------------------*/

.completion-downloads {
  margin-top: 50px;
}

.completion-download {
  margin-bottom: 11px;
}

/* Event-Details */
/* ---------------------------------*/
#event-description {
  margin-bottom: 10px;
}

#event-description img {
  max-width: 100%;
  height: auto;
}

/* Questions */
/* ---------------------------------*/
.question-description a {
  text-decoration: underline;
}

/* Footer */
/* ---------------------------------*/

.footer {
  margin-top: 30px;
  padding: 25px 0;
  width: 100%;
  font-size: 15px;
}

.footer a:hover {
  text-decoration: none;
}

.footer h4 {
  text-transform: uppercase;
  font-size: 1.35rem;
}

.footer-voucher2mobile {
  margin-top: 1.8rem;
}

.footer-shipping-option:not(:last-of-type) {
  margin-bottom: 14px;
}

.voucher2mobile-logo {
  font-size: 2.7rem;
}

.footer-col-title-bar {
  width: 90%;
  height: 2px;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.footer-social {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 0;
}

.footer-social li {
  list-style: none;
  height: 42px;
  width: 42px;
}

.footer-social a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.footer-social svg {
  fill: currentColor;
}

.footer-payment-list {
  font-size: 1.9rem;
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  padding: 0;
}

.footer-payment-list li {
  list-style: none;
}

.footer-payment-list svg {
  fill: currentColor;
}

@include media-breakpoint-down(xs) {
  .footer-col:not(:last-of-type) {
    padding-bottom: 2rem;
  }
}

.powered-by-eguma {
  width: 100%;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.powered-by-eguma a {
  text-decoration: none !important;
}

#breaks {
  background-color: white;
  color: black;
}

/* Setup */
/* ---------------------------------*/

.setup {
  background-color: #ff2800;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid #fff;
  font-size: 1.2em;
  text-align: center;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 2;
}

.button-setup {
  background-color: #fff;
  color: #ff2800;
  font-weight: bold;
  text-transform: none;
  padding: 3px 8px;
  border-radius: 3px;
}

.button-setup:hover {
  color: #ff2800;
}

iframe[src^="https://www.google.com/maps"]
{
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
}

/* for development*/
/* use ?dev=true */

#breaks:before {
  content: "XS - Extra small devices (portrait phones)";
  position: fixed;
  z-index: 1000;
  background-color: white;
}

@include media-breakpoint-up(sm) {
  #breaks:before {
    content: "SM - Small devices (landscape phones, 576px and up)";
  }
}

@include media-breakpoint-up(md) {
  #breaks:before {
    content: "MD - Medium devices (tablets, 768px and up)";
  }
}

@include media-breakpoint-up(lg) {
  #breaks:before {
    content: "LG - Large devices (desktops, 992px and up)";
  }
}

@include media-breakpoint-up(xl) {
  #breaks:before {
    content: "XL - Extra large devices (large desktops, 75em and up)";
  }
}
